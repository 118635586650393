// possibilmente non usare onclick=objQualcosa.metodo perchè altrimenti bisogna creare un sacco di oggetti pubblici
// si può usare data-interacaction e per i parametri data-id o quello che serve e poi in un bindEvents bindare il data-interaction

var formatter = {
    titled: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != undefined && cell_data != "")
            return '<span title="' + cell_data.replace('"', '') + '">' + cell_data + '</span>';
        else
            return "";
    },
    bool: function (table, td, cell_data, row_data, row_index, col_index) {
        return cell_data ? '<i class="fa fa-check green"></i>' : '<i class="fa fa-times red"></i>';
    },

    euro: function (table, td, cell_data, row_data, row_index, col_index) {
        ret = cell_data + " €";
        return ret;
    },
    email: function (table, td, cell_data, row_data, row_index, col_index) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(cell_data)) {
            ret = '<a href=mailto:' + cell_data + ' title="' + cell_data.replace('"', '') + '">' + cell_data + "</a>";
        } else {
            ret = cell_data;
        }

        return ret;
    },
    time_hi: function (table, td, cell_data, row_data, row_index, col_index) {
        return moment(cell_data, "hh:mm:ss").format("HH:mm");
    },

    user_actions: function (table, td, cell_data, row_data, row_index, col_index) {
        return '<button type="button" data-interaction="loginAs" data-id="' + cell_data + '" class="btn btn-white btn-link"><i class="fa fa-sign-in blue"></i></button>';
    },

    dtMulticheck: function (table, td, cell_data, row_data, row_index, col_index) {
        ret = "<input data-interaction='dtMulticheck' type='checkbox'>";
        return ret;
    },

    selectLotto: function (table, td, cell_data, row_data, row_index, col_index) {
        return "<input class='decimal-4' data-max-value='" + row_data[6] + "' style='width: 40%; text-align: right;' type='text' value='" + row_data[6] + "' id='qta" + cell_data + "'><button class='btn btn-xs btn-success' onclick='objLotto.selectLotto(" + cell_data + ",\"" + row_data[6] + "\", \"qta" + cell_data + "\")'><i class='fa fa-plus bigger-110'></i>&nbsp;WIP</button>";
    },

    generaLottoPerDettaglioDocumento: function (table, td, cell_data, row_data, row_index, col_index) {
        if ($.trim(cell_data) == "") {
            return "<button type='button' class='btn btn-xs btn-info' onclick='objLotto.generaLottoPerDettaglioDocumento(" + row_data[0] + ")'><i class='fa fa-plus'></i>&nbsp;Crea Lotto</button>";
        } else {
            return "<i class='badge badge-success'>" + cell_data + "</i>";
        }
    },

    magazzino_posizionale_spostaLotto: function (table, td, cell_data, row_data, row_index, col_index) {
        var ret = $("<div class='dropdown'>");
        ret.append($('<button class="btn btn-default btn-xs dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Muovi in <span class="caret"></span></button>'));

        ret.append($('<ul class="dropdown-menu" aria-labelledby="dropdownMenu1"></ul>'));

        $.each(objMagazzinoPosizionale.localiDisponibili, function (index, obj) {
            if (table.$table.attr("data-idlocale") != obj.id)
                ret.find("ul").append($('<li><a data-interaction="magazzino_posizionale_spostaLotto" href="#" data-id="' + row_data[0] + '" data-idluogo="' + obj.id + '">' + obj.name + '</a></li>'));
        });

        return $("<div>").append(ret).html();
    },

    magazzino_posizionale_variazionePeso: function (table, td, cell_data, row_data, row_index, col_index) {
        return "<button type='button' class='btn btn-xs btn-warning' onclick='objMagazzinoPosizionale.variazionePeso(" + cell_data + ", \"" + table.idTable + "\")'><i class='ace-icon fa fa-balance-scale'>&nbsp;Var. q.ta</i></button>";
    },

    contractor_download: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != '0')
            return "<button title='Scarica documento' type='button' class='btn btn-xs btn-link' data-interaction='download' data-id='" + cell_data + "'><i class='ace-icon fa fa-download'></i></button>";
        else
            return "<button title='Genera documento' type='button' class='btn btn-xs btn-link' data-interaction='genera_contratto' data-id='" + row_data[0] + "'><i class='ace-icon fa fa-cog'></i></button>";
    },

    documento_file_exists: function (table, td, cell_data, row_data, row_index, col_index) {
        var ret = "";
        if ($.trim(cell_data) != "") {
            var data = JSON.parse(cell_data);

            if (typeof data.pdf != "undefined" && data.pdf != null) {
                ret += '<i style="cursor: pointer;" data-interaction="modalSend" data-id="' + data.id + '" data-format="pdf" class="far bigger-150 fa-paper-plane grey" title="' + data.pdf + '"></i>';
            }
            if (typeof data.docx != "undefined" && data.docx != null) {
                ret += '<i style=" cursor: pointer;" data-interaction="modalSend" data-id="' + data.id + '" data-format="docx" class="far bigger-150 fa-paper-plane blue" title="' + data.docx + '"></i>';
            }
        }

        return ret;
    },

    goToDocumentoPagamento: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != "undefined" && cell_data != null) {
            return '<i style="font-size: 15px; margin-right: 5px; cursor: pointer;" data-interaction="goToDocumento" data-id="' + cell_data + '" class="fa fa-wrench blue"></i>';
        }
        return "";
    },

    delete_componente_wip: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != "undefined" && cell_data != null) {
            return '<i style="font-size: 15px; margin-right: 5px; cursor: pointer;" data-interaction="delete_componente_wip" data-id="' + cell_data + '" class="fa fa-trash red"></i>';
        }
        return "";
    },

    importoDiversoDazero: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != "undefined" && cell_data != null && parseFloat(cell_data.replace(",",".")) != 0) {
            return cell_data;
        }
        return "";
    },

    pagamento_calendario: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != null) {
            var color = cell_data == 0 ? 'green' : 'blue';
            return '<button type="button" data-interaction="calendario" class="btn btn-white btn-link btn-xs" data-id="' + cell_data + '"><i class="fa fa-calendar ' + color + '"></i></button>';
        }

        return "";
    },

    sepa_delta: function (table, td, cell_data, row_data, row_index, col_index) {
        if (row_data[7] == "Nessuna")
            return "";
    },

    ortomio_operatore_fotografia: function (table, td, cell_data, row_data, row_index, col_index) {
        return "<img src='" + cell_data + "' data-ortomiooperatore='cambiafotografia' data-callback='ortomio.callback_operatori' data-interaction='dropzone' data-url='ortomio_operatore/cambia_fotografia_operatore' data-parameters='?idOperatore=" + row_data[0] + "' style='width: 20px'>";
    },

    ortomio_turno_show: function (table, td, cell_data, row_data, row_index, col_index) {
        return "<a href='#ortomio_turno/" + cell_data + "'><i class='fa fa-search'></i></a>";
    },

    ortomio_turno_sms_set_template: function (table, td, cell_data, row_data, row_index, col_index) {
        return "<i title='Utilizza questo template' data-interaction='ortomio_turno_sms_set_template' data-id='" + row_data[0] + "' class='clickable fa fa-share-square-o'>&nbsp;</i>";
    },

    sepa_status: function (table, td, cell_data, row_data, row_index, col_index) {
        var ret = "";
        var data = JSON.parse(cell_data);
        ret += '<button type="button" class="btn btn-link" title="Carica il cedolino in PDF" data-callback="obj_sepa_schedule.refreshDetails" data-interaction="dropzone" data-url="' + app.baseUrl + '/sepa_schedule_detail/upload/' + row_data[0] + '" class="btn btn-xs btn-link btn-white"><i style="font-size: 15px;" class="fa fa-upload blue"></i></button>';
        if (typeof data.file != 'undefined' && data.file != null) {

            ret += '<a title="Scarica il cedolino in PDF" href="' + app.baseUrl + '/sepa_schedule_detail/download/' + data.file + '?' + Math.random() + '" type="button" class="btn btn-xs btn-link btn-white"><i style="font-size: 15px;" class="far fa-file-pdf blue"></i></a>';

            if (typeof data.mail != 'undefined' && data.mail.display==true) {
                if (typeof data.mail.status != 'undefined' && data.mail.status != null) {
                    // data da visualizzare nel tooltip
                    var date = '';
                    if (typeof data.mail.date != 'undefined' && data.mail.date != null)
                        date = data.mail.date;

                    // errore da visualizzare nel growl
                    var error = '';
                    if (typeof data.mail.error != 'undefined' && data.mail.error != null)
                        error = data.mail.error;

                    ret += '<button type="button" title="' + date + '" data-interaction="mail_status" data-error="' + error + '" class="btn btn-xs btn-link btn-white"><i style="font-size: 15px;" class="fa fa-envelope ' + data.mail.status + '"></i></button>';
                }
            }
        }

        return ret;
    },

    soggetto_allegato_status: function (table, td, cell_data, row_data, row_index, col_index) {
        var ret = "";
        var data = JSON.parse(cell_data);
        ret += '<button type="button" class="btn btn-link" data-callback="dtSoggettoAllegato.redrawPage" title="Carica il documento in PDF" data-interaction="dropzone" data-url="' + app.baseUrl + '/soggetto_allegato/upload/' + row_data[0] + '" class="btn btn-xs btn-link btn-white"><i style="font-size: 15px;" class="fa fa-upload blue"></i></button>';
        if (typeof data.file != 'undefined' && data.file != null) {

            ret += '<a title="Scarica il documento in PDF" href="' + app.baseUrl + '/soggetto_allegato/download/' + data.file + '?' + Math.random() + '" type="button" class="btn btn-xs btn-link btn-white"><i style="font-size: 15px;" class="far fa-file-pdf blue"></i></a>';

            if (typeof data.mail != 'undefined' && data.mail.display==true) {
                if (typeof data.mail.status != 'undefined' && data.mail.status != null) {
                    // data da visualizzare nel tooltip
                    var date = '';
                    if (typeof data.mail.date != 'undefined' && data.mail.date != null)
                        date = data.mail.date;

                    // errore da visualizzare nel growl
                    var error = '';
                    if (typeof data.mail.error != 'undefined' && data.mail.error != null)
                        error = data.mail.error;

                    ret += '<button type="button" title="' + date + '" data-interaction="mail_status" data-error="' + error + '" class="btn btn-xs btn-link btn-white"><i style="font-size: 15px;" class="fa fa-envelope ' + data.mail.status + '"></i></button>';
                }
            }
        }

        return ret;
    },

    soggetto_saldo: function (table, td, cell_data, row_data, row_index, col_index) {
        var interaction = '';
        switch (col_index) {
            case 6:
                interaction = 'credito';
                break;
            case 7:
                interaction = 'debito';
                break;
            case 8:
                interaction = 'saldo';
                break;
        }

        return '<span data-interaction="' + interaction + '">' + cell_data + '</span>'
    },

    distinta_consegna_actions: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != null) {
            return '<button class="btn btn-xs btn-white btn-link" data-interaction="downloadDDT" data-id="' + cell_data + '"><i class="far fa-file-pdf"></i></button>';
        }

        return '';
    },

    ordini_actions: function (table, td, cell_data, row_data, row_index, col_index) {
        return '<button class="btn btn-xs btn-white btn-link" data-interaction="mettiInConsegna" data-id="' + cell_data + '"><i class="fa fa-arrow-left"></i></button>';
    },

    lotti_disponibili_actions: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != null)
            return '<button class="btn btn-xs btn-white btn-link" data-interaction="deleteLottoDisponibile" data-id="' + cell_data + '"><i class="fa fa-trash red"></i></button>' +
                '<button class="btn btn-xs btn-white btn-link" data-interaction="editLottoDisponibile" data-id="' + cell_data + '"><i class="fa fa-edit warning"></i></button>';

        return '';
    },

    riduzioni_actions: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != null)
            return '<button class="btn btn-xs btn-white btn-link" data-interaction="deleteRiduzione" data-id="' + cell_data + '"><i class="fa fa-trash red"></i></button>' +
                '<button class="btn btn-xs btn-white btn-link" data-interaction="executeRiduzione" data-id="' + cell_data + '"><i class="fa fa-gavel warning"></i></button>';

        return '';
    },

    soggetto_callcenter_actions: function (table, td, cell_data, row_data, row_index, col_index) {
        return '<a href="' + app.baseUrl + '/#documento/create?type=ORD&id_soggetto=' + cell_data + '" title="Crea nuovo ordine" class="btn btn-xs btn-white btn-link"><i class="fa fa-arrow-circle-right blue" style="font-size: 16px;"></i></a>';
    },

    documento_soggetto_actions: function (table, td, cell_data, row_data, row_index, col_index) {
        cell_data = JSON.parse(cell_data);
        return '<a href="' + app.baseUrl + '/#documento/' + cell_data.id + '/edit?type=' + cell_data.type + '" target="_blank" class="btn btn-xs btn-white btn-link"><i class="fa fa-sign-in blue" style="font-size: 16px;"></i></a>';
    },
    third_party_url: function(table, td, cell_data, row_data, row_index, col_index) {
        return '<a style="cursor: pointer" onclick="google_document_picker.createPicker(google_drive_project_attachment_callback,' + row_data[0] + ')" class="google_drive"><img src="images/google_drive.png"></a> ' + (cell_data != 'https://#' ? '<a href="' + cell_data + '" target="_blank">Apri documento</a>' : '');
    },

    url: function(table, td, cell_data, row_data, row_index, col_index) {
        return '<a title="Apri" style="cursor: pointer" href="' + cell_data + '" class="btn btn-link"><i class="fa fa-arrow-right"></i></a>';
    },

    hotel_booking_room_default_actions: function(table, td, cell_data, row_data, row_index, col_index) {
        return '<button type="button" data-interaction="subject" data-id="' + cell_data + '" class="btn btn-link btn-white"><i class="fa fa-users blue"></i></button>';
    },

    showNoteScadenziario: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != "undefined" && cell_data != null) {
            return '<i style="font-size: 15px; margin-right: 5px; cursor: pointer;" data-interaction="showNoteScadenziario" data-value=\"'+cell_data+'\" class="fa fa-binoculars blue"></i>';
        }
        return "";
    },


};
