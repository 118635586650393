var hotel_booking = function (options) {

    var hotel_booking = this;

    this.calendar = undefined;

    this.initCalendar = function ()
    {
        var extra = parseInt($("#extra").val());
        this.calendar = $("#calendar").fullCalendar({
            defaultView: 'timeline30Days',
            schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
            editable: false,
            height: 'auto',
            resourceAreaWidth: '20%',
            defaultDate: moment().subtract(10, 'days'),
            scrollTime: moment(),
            eventOverlap: false,
            views: {
                timeline30Days: {
                    type: 'timeline',
                    duration: {days: 30}
                }
            },
            resourceLabelText: 'Camere',
            resources: options.resources,
            events: options.events,
            dayClick: function (date, jsEvent, view, resourceObj) {
                if (!extra) {
                    app.href('hotel_booking/create?checkIn=' + date.format('DD/MM/YYYY') + '&checkOut=' + date.add(1, 'days').format('DD/MM/YYYY') + '&id_hotel_booking_room_selected=' + resourceObj.id);
                }
            },
            eventClick: function (calEvent, jsEvent, view) {
                if (typeof calEvent.id_booking != 'undefined') {
                    // apro il modal di selezione operazione
                    $("#id_hotel_booking").val(calEvent.id_booking);
                    $("#id_hotel_booking_room").val(calEvent.id);
                    if (calEvent.checkin)
                        $("#modalOperation").find('[data-interaction=checkout]').removeAttr('disabled');
                    else
                        $("#modalOperation").find('[data-interaction=checkout]').attr('disabled', 'disabled');
                    $("#modalOperation").modal('show');
                }
            }
        });

        $('[data-interaction=extra]').unbind('click').bind('click', function () {
            $("#modalOperation").modal('hide');
            app.href("hotel_booking/" + $("#id_hotel_booking").val() + "/edit?id_hotel_booking_room=" + $("#id_hotel_booking_room").val() + "&extra=1");
        });

        $('[data-interaction=pagamento]').unbind('click').bind('click', function () {
            $("#modalOperation").modal('hide');
            app.href("hotel_booking/" + $("#id_hotel_booking").val() + "/payment");
        });

        $('[data-interaction=prenotazione]').unbind('click').bind('click', function () {
            $("#modalOperation").modal('hide');
            app.href("hotel_booking/" + $("#id_hotel_booking").val() + "/edit?id_hotel_booking_room=" + $("#id_hotel_booking_room").val());
        });

        $('[data-interaction=checkin]').unbind('click').bind('click', function () {
            $("#modalOperation").modal('hide');
            app.href("hotel_booking_room_subject?id_hotel_booking_room=" + $("#id_hotel_booking_room").val());
        });

        $('[data-interaction=checkout]').unbind('click').bind('click', function () {
            $("#modalOperation").modal('hide');
            app.href("hotel_booking_room_subject?id_hotel_booking_room=" + $("#id_hotel_booking_room").val());
        });
    };

    this.bindEventsPayment = function () {

        if ($("#invoice_div").length > 0) {
            $.get(app.baseUrl + "/documento", {id_hotel_booking: $("#frmHotelBooking").attr('data-id')})
                .done(function (data) {
                    $("#invoice_div").html(data);
                });
        }

        $(".invoice_element").on('click', function () {
            aggiorna_totali_payment_hotel();
        });

        $('[data-interaction=selectAllItem]').unbind('click').bind('click', function () {
            $(".invoice_element").prop('checked', true);
            aggiorna_totali_payment_hotel();
        });

        $('[data-interaction=createInvoice]').unbind('click').bind('click', function () {
            app.block(1);
            var subject = $("#payment_subject").val();
            if (typeof subject == 'undefined' || $.trim(subject) == '') {
                app.warning("", "Devi selezionare almeno un soggetto per la fatturazione");
                app.block(0);
                return;
            }

            var type = $("#document_type").val();
            if (typeof type == 'undefined' || $.trim(type) == '') {
                app.warning("", "Devi selezionare almeno un tipo documento per la fatturazione");
                app.block(0);
                return;
            }

            var id_subject = [];
            var id_extra = [];
            $(".invoice_element").each(function () {
                if ($(this).is(":checked")) {
                    var type = $(this).attr('data-type');
                    var id = $(this).attr('data-id');

                    if (type == 'subject')
                        id_subject.push(id);
                    if (type == 'extra')
                        id_extra.push(id);
                }
            });

            if (id_subject.length == 0 && id_extra == 0) {
                app.warning("", "Devi selezionare almeno un elemento per la fatturazione");
                app.block(0);
                return;
            }

            $.post(app.baseUrl + "/documento/create_from_booking", {subject: subject, type: type, id_subject: id_subject, id_extra: id_extra})
                .done(function (data) {
                    if (data.response) {
                        app.success('', 'Generazione documento avvenuto con successo');
                        if ($("#pagamento_tab").length > 0) {
                            $("#pagamento_tab").trigger('click');
                        } else {
                            app.reload();
                        }
                    } else {
                        app.warning('', data.message);
                    }

                    $('#data').datetimepicker('hide');

                });
            app.block(0);
        });

        $('[data-interaction=editInvoiceSubject]').unbind('click').bind('click', function () {
            var id = parseInt($("#payment_subject").val());

            if (!id) {
                app.warning("", "Devi selezionare un soggetto valido");
                return;
            }

            $.get(app.baseUrl + "/hotel_subject/" + id + "/edit?modal=1")
                .done(function (data) {
                    $("#modalSubject").find('.modal-body').html(data);
                    app.runBind();
                    $("#modalSubject").modal('show');
                });
        });

        $('[data-interaction=newInvoiceSubject]').unbind('click').bind('click', function () {
            $("#modalPayment").modal('show');
        });

        $('[data-interaction=saveSubjectPayment]').unbind('click').bind('click', function () {
            if ($.trim($("#ragione_sociale").val()) == "") {
                app.warning("", "Inserire almeno il nominativo");
                return;
            }

            var c = new crud({
                form: 'frmSoggetto'
            });

            c.formSubmit($("#frmSoggetto").attr('action'), $("#frmSoggetto"), {}, function (data) {
                if (data.response) {
                    $("#payment_subject").find('option').removeAttr('selected');
                    $("#payment_subject").append('<option value="' + data.message + '" selected>' + $("#frmSoggetto").find("#ragione_sociale").val() + '</option>');
                    $("#modalPayment").modal("hide");

                } else {
                    app.warning(data.message);
                }
            }, function (data) {
                app.error("Errore!");
            });
        });
    };

    this.bindEvents = function () {
        $("#id_subject").select2({
            ajax: {
                url: app.baseUrl + "/soggetto/autocomplete_soggetto?tipo_soggetto=1",
                dataType: 'json',
                delay: 100,
                type: 'POST',
                data: function (params) {
                    return {
                        id: params.id,
                        search: params.term, // search term
                        page: params.page
                    };
                },
                processResults: function (data, params) {
                    // parse the results into the format expected by Select2
                    // since we are using custom formatting functions we do not need to
                    // alter the remote JSON data, except to indicate that infinite
                    // scrolling can be used
                    params.page = params.page || 1;

                    return {
                        results: data.items
                    };
                },
                cache: true
            },
            containerCssClass: "data-dt-focus",
            tags: true,
            escapeMarkup: function (markup) {
                return markup;
            }, // let our custom formatter work
            minimumInputLength: 0,
            allowClear: true,
            placeholder: {
                id: "",
                placeholder: ""
            },
            cache: true,
            templateResult: function (data) {
                if (data.placeholder)
                    return data.placeholder;

                return data.text;
            },
            templateSelection: function (data) {
                if (data.placeholder)
                    return data.placeholder;

                if (typeof data.label == 'undefined')
                    data.label = data.text;
                return data.label;
            }
        }).on("select2:open", function(e) {
            if ($(this).data('state') === 'unselected') {
                $(this).removeData('state');

                var self = $(this);
                setTimeout(function() {
                    self.select2('close');
                }, 1);
            }
        });

        $("#id_subject").on('change', function () {
            app.suppressWarning = true;
            $.get(app.baseUrl + "/soggetto/" + $(this).val())
                .done(function (data) {
                    if (data.response) {
                        $("#dump_tel").val(data.message.telefono);
                        $("#dump_email").val(data.message.email1);
                        app.suppressWarning = false;
                    } else {
                        app.warning("", data.message);
                        app.suppressWarning = false;
                    }
                })
                .fail(function () {
                    $("#dump_tel").val("");
                    $("#dump_email").val("");
                });
        });

        if ($("#hotel_booking_room").length > 0) {
            $.get(app.baseUrl + "/hotel_booking_room", {id_hotel_booking: $("#frmHotelBooking").attr('data-id')})
                .done(function (data) {
                    $("#hotel_booking_room").html(data);
                });
        }

        $("#pagamento_tab").unbind('click').bind('click', function () {
            if ($("#pagamento").length > 0) {
                var idHotelBooking = $("#frmHotelBooking").attr('data-id');
                $.get(app.baseUrl + "/hotel_booking/" + idHotelBooking + "/payment")
                    .done(function (data) {
                        $("#pagamento").html(data);
                        app.runBind();
                    });
            }
        });
    };

    this.loadExtra = function ()
    {
        if ($("#extra").length > 0) {
            var idRoom = $("#id_hotel_booking_room").val();

            $.get(app.baseUrl + "/hotel_booking_room_extra", {id_hotel_booking_room: idRoom, extra: 1})
                .done(function (data) {
                    $("#extra").html(data);
                });
        }
    }
};

function aggiorna_totali_payment_hotel () {
    var tot = [];
    $(".invoice_element").each(function () {
        var room = $(this).attr('data-room');
        var price = $(this).attr('data-price');

        if (typeof tot[room] == 'undefined')
            tot[room] = 0.0;

        if ($(this).is(":checked")) {
            tot[room] += parseFloat(price);
        }
    });

    $.each(Object.keys(tot), function () {
        $(".invoice_total[data-room=" + this + "]").html(tot[this] + "€");
    });
}