var documento = function (dt) {
    var documento = this;
    //variabile per salvarmi il contenuto del pulsante invia situazione per email in rubrica
    var button_send_content = "";

    this.idDocumento = parseInt($("#frmDocumento").attr("data-id")) || 0;

    this.afterSave = function (data) {
        if (data.response && documento.constructor.contabilizza) {
            documento.stampa([documento.idDocumento], 'pdf', 0);
            // documento.stampa([documento.idDocumento], 'docx', 0);

            // documento.constructor.contabilizza = false;
        }

        if (typeof data.refresh_number !== 'undefined' && data.refresh_number == true) {

            // se il documento nuovo appena salvato ha un numero già presente su DB, viene ricalcolato il nuovo numero di documento
            $.get(app.baseUrl + "/documento/refresh_document_number", {
                type: $("#type").val(),
                id_documento: documento.idDocumento
            }, function (data) {
                app.warning("Il numero di documento è stato aggiornato");
                $("#numero").val(data.message);
            });
        }
    };

    /**
     * Oggetto contenente il mapping tra label tipologia documento e ralativo id
     *
     * @type {{}}
     */
    this.mappingTipologieDocumento = {};

    this.setMappingTipologieDocumento = function (json) {
        documento.mappingTipologieDocumento = json;
    };

    $("[data-interaction=delete_documento]").unbind("click").bind("click", function (e) {
        e.preventDefault();
        var url = $(this).attr('data-url');
        var href = $(this).attr('data-href');
        var errore = $(this).attr("data-error");
        if (confirm('Eliminare questo documento?')) {
            $.delete(url)
                .done(function (data) {
                    if (data.response) {
                        app.success("", "Operazione eseguita correttamente!");
                        app.href(href);
                    }
                    else {
                        app.warning("", data.message)
                    }
                })
                .fail(function () {
                    app.error("", errore);
                })
        }
    });
    this.bindDeleteEvent = function (dt) {
        $('[data-interaction=delete]').unbind('click').bind('click', function (e) {
            e.preventDefault();
            app.block(1);
            var url = $(this).attr('data-url');
            var id = $(this).closest("tr").attr("data-id");

            if (confirm('Eliminare la riga selezionata?')) {
                $.post(app.baseUrl + "/dettaglio_documento/check_used_lotto", {id: id})
                    .done(function (data) {
                        if (data.response) {
                            $.delete(url)
                                .success(function (data) {
                                    if (data.response) {
                                        dt.redrawPage();

                                        // se è tra gli elementi selezionati lo rimuovo
                                        if (dt.selected_ids.indexOf(id) >= 0)
                                            dt.selected_ids.splice(dataTable.selected_ids.indexOf(id), 1);

                                        dt.afterDeleteRow();
                                    } else {
                                        app.warning("", data.message);
                                    }
                                    app.block(0);
                                })
                                .error(function () {
                                    app.block(0);
                                    app.error('', 'Delete error!');
                                });
                        } else {
                            app.warning("", data.message);
                        }
                    });
            }
            app.block(0);
        });
    };

    this.bindDropdown = function (types) {
        $("#" + dt.idTable + "_wrapper").find("#" + dt.idTable + "_filter").find('.dropdown-toggle').each(function () {
            var $div = $('<div class="dropdown" style="display: inline-block;"></div>');
            $(this).attr('data-toggle', 'dropdown');
            $div.html($(this).clone());

            var $ul = $('<ul class="dropdown-menu"></ul>');
            var interaction = $(this).hasClass('genera') ? 'genera' : 'scarica';
            if ($(this).hasClass('fatture_elettroniche'))
                interaction = 'fatture_elettroniche';
            var zip = $(this).hasClass('zip') ? 1 : 0;
            $.each(types, function (i, v) {
                $ul.append('<li><a style="cursor: pointer;" data-interaction="' + interaction + '" data-zip="' + zip + '" data-format="' + v + '">' + v.toUpperCase() + '</a></li>');
            });

            $div.append($ul);

            $(this).replaceWith($div);

            documento.bindGeneraScarica(dt);
        });
    };

    this.bindSendDocumento = function () {
        // var $modal = $('#modalSend');
        // $modal.find("#messaggio").wysiwyg();

        $('[data-interaction=modalSend]').unbind('click').bind('click', function () {
            var id = $(this).attr('data-id');
            var format = $(this).attr('data-format');

            $.get(app.baseUrl + "/documento/" + id)
                .done(function (data) {
                    var $modal = $("#modalSend");


                    //CKEDITOR.config.customConfig = false;
                    //CKEDITOR.config.contentsCss = false;
                    //CKEDITOR.config.plugins = "dialogui,dialog,a11yhelp,about,basicstyles,blockquote,clipboard,panel,floatpanel,menu,contextmenu,elementspath,indent,indentlist,list,enterkey,entities,popup,filebrowser,floatingspace,listblock,button,richcombo,format,horizontalrule,htmlwriter,image,fakeobjects,link,magicline,maximize,pastefromword,pastetext,removeformat,resize,menubutton,showborders,sourcearea,specialchar,stylescombo,tab,table,tabletools,toolbar,undo,wysiwygarea";

                    var prevEditor = CKEDITOR.instances["messaggio"];
                    if (prevEditor != null)
                        CKEDITOR.instances["messaggio"].destroy();
                    CKEDITOR.replace('messaggio');
                    // $modal.find("#messaggio").ckeditor();
                    $modal.find('[data-interaction=tipoDocumento]').html(data.tipoDocumento);
                    $modal.find('[data-interaction=numero]').html(data.numero);
                    if ($.trim($modal.find('#from').attr("data-user")) != "")
                        $modal.find('#from').val($modal.find('#from').attr("data-user"));
                    else
                        $modal.find('#from').val($modal.find('#from').attr("data-env"));

                    if ($.trim($modal.find('#from_name').attr("data-user")) != "")
                        $modal.find('#from_name').val("ERP - " + $modal.find('#from_name').attr("data-user"));
                    else
                        $modal.find('#from_name').val($modal.find('#from_name').attr("data-env"));
                    $modal.find("#id").val(data.id);
                    $modal.find("#format").val(format);
                    $modal.find("#to").val(data.to);
                    $modal.find("#cc").val(data.cc);
                    $modal.find("#ccn").val(data.ccn);
                    $modal.find("#oggetto").val(data.oggetto);
                    $modal.find("#messaggio").val(data.messaggio);

                    $modal.find(".cke").css('visibility', 'visible');

                    $modal.find('[data-dismiss=modal]').unbind('click').bind('click', function () {
                        dt.redrawPage();
                    });

                    $modal.modal('show');
                });
        });

        $('[data-interaction=sendDocumento]').unbind('click').bind('click', function () {
            // raccolgo le informazioni ed invio l'email
            var $modal = $("#modalSend");

            var id = $("#modalSend").find("#id").val();

            var params = {
                format: $modal.find("#format").val(),
                to: $modal.find("#to").val(),
                cc: $modal.find("#cc").val(),
                ccn: $modal.find("#ccn").val(),
                oggetto: $modal.find("#oggetto").val(),
                messaggio: CKEDITOR.instances["messaggio"].getData(),
                from: $modal.find("#from").val(),
                from_name: $modal.find("#from_name").val()

            };

            app.block(1);
            $.post(app.baseUrl + "/documento/" + id + "/send", params)
                .done(function (data) {
                    if (data.response) {
                        app.success("", "Email inviata!");
                        $modal.find('[data-dismiss=modal]').trigger('click');
                    } else {
                        app.warning("", data.message);
                    }
                    app.block(0);
                });
        });
    };

    this.openModalSendSituazione = function (id) {
        $.get(app.baseUrl + "/soggetto/" + id + "/situazione")
            .done(function (data) {

                var $modal = $("#modalSendSituazione");


                $modal.find('[data-interaction=tipoDocumento]').html(data.tipoDocumento);
                $modal.find('[data-interaction=numero]').html(data.numero);

                $modal.find("#id").val(data.id);
                $modal.find("#format").val('pdf');
                $modal.find("#to").val(data.to);
                $modal.find("#ccn").val(data.ccn);
                $modal.find("#oggetto").val(data.oggetto);
                $modal.find("#messaggio").val(data.messaggio);

                /*CKEDITOR.config.customConfig = false;
                 CKEDITOR.config.contentsCss = false;
                 CKEDITOR.config.plugins = "dialogui,dialog,a11yhelp,about,basicstyles,blockquote,clipboard,panel,floatpanel,menu,contextmenu,elementspath,indent,indentlist,list,enterkey,entities,popup,filebrowser,floatingspace,listblock,button,richcombo,format,horizontalrule,htmlwriter,image,fakeobjects,link,magicline,maximize,pastefromword,pastetext,removeformat,resize,menubutton,showborders,sourcearea,specialchar,stylescombo,tab,table,tabletools,toolbar,undo,wysiwygarea";
                 $modal.find("#messaggio").ckeditor();*/

                var messaggio = $modal.find("#messaggio")[0];
                var prevEditor = CKEDITOR.instances["messaggio"];
                if (prevEditor != null)
                    CKEDITOR.instances["messaggio"].destroy();
                CKEDITOR.replace(messaggio);

                $modal.find(".cke").css('visibility', 'visible');

                $modal.find('[data-dismiss=modal]').unbind('click').bind('click', function () {
                    dt.table.draw('page');
                });

                $modal.modal('show');

                documento.bindSendSituazione();

            });
    };

    this.bindSendSituazione = function () {


        $('[data-interaction=sendSituazione]').unbind('click').bind('click', function () {
            // raccolgo le informazioni ed invio l'email
            var $modal = $("#modalSendSituazione");

            var id = $("#modalSendSituazione").find("#id").val();

            var params = {
                format: $modal.find("#format").val(),
                to: $modal.find("#to").val(),
                cc: $modal.find("#cc").val(),
                ccn: $modal.find("#ccn").val(),
                oggetto: $modal.find("#oggetto").val(),
                messaggio: CKEDITOR.instances["messaggio"].getData()
            };
            app.block(1);
            $.post(app.baseUrl + "/soggetto/" + id + "/sendSituazione", params)
                .done(function (data) {
                    if (data.response) {
                        app.success("", "Email inviata!");
                        $modal.find('[data-dismiss=modal]').trigger('click');
                    } else {
                        app.warning("", data.message);
                    }
                    app.block(0);
                });
        });
    };

    this.newNumero = function () {
        var type = $("#type").val();
        var anno = moment($("#data").val(), 'DD/MM/YYYY').format('YYYY');
        var sezionale = $("#sezionale").val();

        app.block(1);
        $.post(app.baseUrl + "/documento/numero", {type: type, anno: anno, sezionale: sezionale})
            .done(function (data) {
                if (data.response) {
                    $('#numero').val(data.message.numero);
                    $('#protocollo_interno').val(data.message.protocollo_interno);
                } else {
                    app.warning('', data.message);
                }
                $('#data').datetimepicker('hide');
                app.block(0);

            });
    };

    this.bindMettiInElaborazione = function () {
        $('[data-interaction=mettiInConsegna]').unbind('click').bind('click', function () {
            documento.mettiInElaborazione([$(this).attr('data-id')]);
        });
    };

    this.bindMettiInConsegna = function () {
        $('[data-interaction=mettiInConsegna]').unbind('click').bind('click', function () {
            documento.mettiInConsegna([$(this).attr('data-id')]);
        });

        $('[data-interaction=stampa_distinta_prodotti_consegna]').unbind('click').bind('click', function (e) {
            e.preventDefault();
            documento.stampa_distinta_prodotti_consegna();
        });
    };

    this.stampaSituazione = function () {
        app.block(1);
        $.post(app.baseUrl + "/soggetto/" + $("#idSoggetto").val() + "/genera_situazione")
            .done(function (data) {
                if (data.response) {
                    window.location.href = app.baseUrl + "/soggetto/" + $("#idSoggetto").val() + "/scarica_situazione";
                    $.post(app.baseUrl + "/soggetto/" + $("#idSoggetto").val() + "/check_fatture")
                        .done(function (data) {
                            if (data.length > 0) {
                                documento.stampa(data, "pdf", 0);
                                documento.button_send_content = $("[data-interaction=modalSendSituazione]").closest("button").html();
                                $("[data-interaction=modalSendSituazione]").closest("button").html("<i class='fa fa-spinner fa-spin' data-interaction='modalSendSituazione'></i> Contabilizzando");
                            }
                            else {
                                $("[data-interaction=modalSendSituazione]").closest("button").attr("disabled", false);
                            }
                            /*if (data.response) {
                                $("[data-interaction=modalSendSituazione]").closest("button").attr("disabled", false);
                             }
                             else
                             {
                             app.warning("",data.message);
                             }*/
                        })


                } else {
                    app.warning("", data.message);
                }

                app.block(0);
            })
            .fail(function (data) {
                app.block(0);
                var error = "Errore AJAX!";
                if (typeof data.status != 'undefined' &&
                    data.status == 422 &&
                    typeof data.responseJSON == 'object' &&
                    typeof data.responseJSON.value != 'undefined' &&
                    data.responseJSON.value.length > 0
                ) {
                    error = data.responseJSON.value[0];
                }
                app.error("", error);
            });
    };

    this.bindEvents = function () {
        $('#data').unbind('change').bind('change', function () {
            // gestione vecchio valore
            var data_old = $(this).attr('data-old');
            var data_now = $(this).val();

            var year_old = data_old.substring(6, 10);
            var year_now = data_now.substring(6, 10);

            if (year_now != year_old && confirm("Vuoi ricalcolare il numero/protocollo interno?")) {

                $(this).attr('data-old', $(this).val());
                documento.newNumero();
            }
        });

        $('#sezionale').unbind('select2:select').bind('select2:select', function () {
            if (!confirm("Vuoi ricalcolare il numero della fattura (per la vendita)/protocollo interno (per gli acquisti)?"))
                return;

            documento.newNumero();
        });

        $("[data-interaction=applicaBollo]").unbind("click").bind("click", function(){
            app.block(true);
            $.post("documento/" + $("#frmDocumento").attr("data-id") + "/applica_bollo", function(){
                app.block(false);
                app.reload();
            });
        });

        // template di visualizzazione dei risultati della select2
        function templateResult(data) {
            return data.text;
        }

        // template di visualizzazione del risultato selezionato della select2
        function templateSelection(data) {
            if (typeof data.label == "undefined")
                data.label = data.text;
            return data.label;
        }

        $("#id_soggetto").select2({
            ajax: {
                url: $("#id_soggetto").attr("data-url"),
                dataType: 'json',
                delay: 100,
                type: 'POST',
                data: function (params) {
                    return {
                        search: params.term, // search term
                        page: params.page
                    };
                },
                processResults: function (data, params) {
                    // parse the results into the format expected by Select2
                    // since we are using custom formatting functions we do not need to
                    // alter the remote JSON data, except to indicate that infinite
                    // scrolling can be used
                    params.page = params.page || 1;

                    return {
                        results: data.items
                    };
                },
                cache: true
            },
            escapeMarkup: function (markup) {
                return markup;
            },
            minimumInputLength: 1,
            allowClear: true,
            cache: true,
            allowClear: false,
            templateResult: templateResult,
            templateSelection: templateSelection
        }).on("select2:unselecting", function (e) {
            $(this).data('state', 'unselected');
        });

        $("#id_tipo_pagamento").unbind("select2:select").bind("select2:select", function () {
            if ($.trim($("#id_tipo_pagamento").val()) != '') {
                setDataScadenza($("#id_tipo_pagamento").val(), $("#data").val());
            }
            else
                $("#data_scadenza_pagamento").val("");
        });

        $("#id_soggetto").unbind("select2:select").bind("select2:select", function () {
            var idSoggetto = $(this).val();

            var s = new soggetto();
            s.get(idSoggetto, function (data) {
                if (data) {
                    $("#soggetto_ragione_sociale").val(data.ragione_sociale);
                    $("#soggetto_paritita_iva").val(data.partita_iva);
                    $("#soggetto_cf").val(data.codice_fiscale);
                    $("#soggetto_indirizzo").val(data.indirizzo);
                    $("#soggetto_nazione").val(data.nazione);
                    $("#soggetto_comune").val(data.comune);
                    $("#soggetto_codice").val(data.codice);
                    $("#soggetto_provincia").val(data.provincia);
                    $("#soggetto_cap").val(data.cap);
                    $("#id_tipo_pagamento").val(data.id_tipo_pagamento).change();
                    $("#note").val(data.note_fattura);
                    $("#dump_id_aliquota_iva_forzata").val(data.id_aliquota_iva_forzata);
                    $("#ddt_trasportatore").val(data.default_ddt_trasportatore);
                    $("#ddt_mezzo").val(data.default_ddt_mezzo).trigger("change");
                    $("#ddt_vettore").val(data.default_ddt_vettore);
                    $("#ddt_porto").val(data.default_ddt_porto).trigger("change");

                    $("#fe_esigibilita_iva").val(data.esigibilita_iva).trigger("change");


                    setDataScadenza(data.id_tipo_pagamento, $("#data").val());


                    var selectedValues = data.default_ddt_aspetto.split(', ');
                    $("#ddt_aspetto").val(selectedValues).trigger("change");

                    $("input[name='dump_id_aliquota_iva_forzata']").val(data.id_aliquota_iva_forzata);

                    // gestione ddt cn prezzi
                    if (data.ddt_con_prezzi)
                        $("#ddt_con_prezzi").prop("checked", true);

                    // unita locale
                    if (typeof data.unita_locale != 'undefined' && Object.keys(data.unita_locale).length > 0) {
                        $("#id_unita_locale").html('<option value="">&nbsp;</option>');
                        $.each(Object.keys(data.unita_locale), function (i, v) {
                            $("#id_unita_locale").append('<option value="' + v + '">' + data.unita_locale[v] + '</option>');
                        });

                        if (typeof data.unita_locale_default != 'undefined' && parseInt(data.unita_locale_default)) {
                            $('#id_unita_locale').val(data.unita_locale_default).trigger("change");
                            app.success("Inserimento sede di spedizione", "Operarazione effettuata correttamente");

                        }
                    } else {
                        //non ci sono unità locali quindi ripulisco i campi relativi nel caso in cui si cambi soggetto
                        $("[data-interaction=resetUnitaLocale]").trigger("click");
                    }

                    // avviso utente se soggetto senza codice univoco / pec
                    if (typeof data.fe != 'undefined') {
                        if (!data.fe.status) {
                            app.warning(data.fe.errorMessage);
                        }
                    }

                }
            });

            if (idSoggetto != null && $("#agent_contracts").length > 0) {
                $.get(app.baseUrl + "/soggetto/" + idSoggetto + "/get_agent_contract", {date: $("#data").val()})
                    .done(function (data) {
                        if (data.response) {
                            $("#id_agent_contract").val(data.message.id);
                            $("#agent_contract").val(data.message.to_string);
                        }
                    })
            }

        });

        $("[data-interaction=reset-data-scadenza-pagamenti]").unbind("click").bind("click", function () {
            setDataScadenza($("#id_tipo_pagamento").val(), $("#data").val());
        });

        function setDataScadenza(id_tipo_pagamento, dataF) {
            $.post(app.baseUrl + "/documento/calcola_scadenza", {idTipoPagamento: id_tipo_pagamento, data: dataF})
                .done(function (data) {
                    for (var i = 0; i < data.length; i++) {
                        data[i] = moment(data[i], 'YYYY-MM-DD').format('DD/MM/YYYY');
                    }
                    $("#data_scadenza_pagamento").val(data.join(", "));
                });
        }

        $("#id_unita_locale").on("change", function () {
            var idUnitaLocale = $(this).val();

            if ($.trim(idUnitaLocale) != "") {
                var s = new unita_locale();
                s.get(idUnitaLocale, function (data) {
                    if (data) {
                        $("#soggetto_ul_nazione_iso_alpha2").val(data.nazione_iso_alpha2);
                        $("#soggetto_ul_nazione_iso_alpha2").trigger('change');
                        $("#soggetto_ul_nazione").val(data.nazione);
                        $("#soggetto_ul_nome").val(data.nome);
                        $("#soggetto_ul_cognome").val(data.cognome);
                        $("#soggetto_ul_telefono").val(data.telefono);
                        $("#soggetto_ul_indirizzo").val(data.indirizzo);
                        $("#soggetto_ul_comune").val(data.comune);
                        $("#soggetto_ul_provincia").val(data.provincia);
                        $("#soggetto_ul_cap").val(data.cap);
                    }
                });
            }

        });

        $("[data-interaction='inserireScadenziario']").unbind("click").bind("click", function () {
            if($('#id_tipo_pagamento').val() === '') {
                app.warning('Inserire prima un tipo di pagamento');
                return;
            }

            $(".btnAggiungiScadenza").attr("data-fromWarningDiv", 1);
            $(".btnAggiungiScadenza").click();
            $(".btnAggiungiScadenza").removeAttr("data-fromWarningDiv");
            $(".btnAggiungiScadenza").attr("disabled", "disabled");
            //this.disable();
        });


        $("[data-interaction='eliminaBollo']").unbind("click").bind("click", function () {
            var id = $(this).attr("data-id");
            $.post(app.baseUrl + "/documento/elimina_bollo", {
                id : id
            }, function (data) {
                if (data.response) {
                    location.reload();
                    app.block(0);
                } else {
                    app.error("", data.message);
                    app.block(0);
                }
            });

        });

        $("[data-interaction=resetUnitaLocale]").unbind("click").bind("click", function () {
            $("#id_unita_locale").val("");
            $("#id_unita_locale").trigger("change");
            $("#soggetto_ul_nome").val("");
            $("#soggetto_ul_cognome").val("");
            $("#soggetto_ul_telefono").val("");
            $("#soggetto_ul_nazione").val("");
            $("#soggetto_ul_indirizzo").val("");
            $("#soggetto_ul_comune").val("");
            $("#soggetto_ul_provincia").val("");
            $("#soggetto_ul_cap").val("");
        });

        $("[data-interaction=print]").unbind("click").bind("click", function () {
            var id = $(this).attr("data-id");
            var format = $(this).attr("data-filetype");

            documento.stampa([id], format, 1);
        });

        $("[data-interaction=send_edocumento]").unbind("click").bind("click", function () {

            var id = $(this).attr("data-id");
            var url = $(this).attr("data-url");

            if (!confirm("Si conferma di voler inviare la fattura elettronica? L'operazione non sarà reversibile!")) {
                return true;
            }

            app.block(1);
            $.post(url, {
                id: id
            }, function (data) {
                if (data.response) {
                    app.success("Fattura Elettronica", "Invio al SDI effettuato correttamente");
                } else {
                    app.error("", data.message);
                }

                app.block(0);
                app.reload();
            });
        });


        $("[data-interaction=contabilizza]").unbind("click").bind("click", function () {
            if (!confirm("Il processo richiede qualche istante durante il quale non potrai scaricare il documento. Procedere?"))
                return;

            documento.constructor.contabilizza = true;

            // salvo il documento
            app.block(1);
            $('[data-interaction=save]').trigger('click');
        });

        $("[data-interaction=elaboraGenerazioneAutomatica]").unbind("click").bind("click", function () {
            var tipoFrom = $("#id_tipo_documento_from").val();
            var tipoTo = $("#id_tipo_documento_to").val();
            var dataNuovo = $("#data_nuovo").val();
            var dataInizioRicerca = $("#data_ricerca").val();
            var dataFineRicerca = $("#data_ricerca_fine").val();
            var forceCreazione = $("#forza_esistenti").is(":checked") ? 1 : 0;
            var raggruppa_prodotti_ddt = $("#raggruppa_prodotti_ddt").is(":checked") ? 1 : 0;
            var add_indirizzo_spedizione = $("#add_indirizzo_spedizione").is(":checked") ? 1 : 0;
            var id_documento = $("#idDocumento").val();
            var id_soggetto = $("#id_soggetto").val();
            var data_scadenza_fattura = $("#data_scadenza_fattura").val();
            var elimina_pagamenti_proforma_da_scadenziario = $("#elimina_pagamenti_proforma_da_scadenziario").is(":checked") ? 1 : 0;
            var accorpa_fattura_per_soggeto = $("#accorpa_fattura_per_soggeto").is(":checked") ? 1 : 0;
            var accorpa_fattura_per_soggeto_unita_locale = $("#accorpa_fattura_per_soggeto_unita_locale").is(":checked") ? 1 : 0;
            var inserisci_scadenziario = $("#inserisci_scadenziario").is(":checked") ? 1 : 0;
            var sezionale = $("#sezionale").val();
            if (forceCreazione) {
                if (!confirm("Elaborando i documenti con il flag Forza Esistenti verranno generati i documenti correlati anche laddove quest'operazione sia già stata fatta in passato. Procedere comunque?")) {
                    return true;
                }
            }

            app.block(1);
            $.post(app.baseUrl + "/documento/elabora_generazione_automatica", {
                elimina_pagamenti_proforma_da_scadenziario: elimina_pagamenti_proforma_da_scadenziario,
                tipoFrom: tipoFrom,
                tipoTo: tipoTo,
                dataNuovo: dataNuovo,
                data_scadenza_fattura: data_scadenza_fattura,
                dataInizioRicerca: dataInizioRicerca,
                dataFineRicerca: dataFineRicerca,
                forceCreazione: forceCreazione,
                raggruppa_prodotti_ddt: raggruppa_prodotti_ddt,
                add_indirizzo_spedizione: add_indirizzo_spedizione,
                id_documento: id_documento,
                id_soggetto: id_soggetto,
                inserisci_scadenziario: inserisci_scadenziario,
                accorpa_fattura_per_soggeto: accorpa_fattura_per_soggeto,
                accorpa_fattura_per_soggeto_unita_locale: accorpa_fattura_per_soggeto_unita_locale,
                sezionale: sezionale
            }, function (data) {
                if (data.response) {
                    if (confirm("Generazione di " + data.message + " documenti avvenuta con successo. Accedere alla gestione dei documenti?")) {
                        app.href(app.baseUrl + "/documento?type=" + $("[data-interaction=elaboraGenerazioneAutomatica]").attr("data-format-to"));
                    }
                    app.block(0);
                } else {
                    app.error("", data.message);
                    app.block(0);
                }
            }).error(function(data){
                if(typeof data.responseJSON != 'undefined' && typeof data.responseJSON.message != 'undefined') {
                    app.error(data.responseJSON.message);
                    app.block(0);
                }
            });

        });


    };

    this.makeOrderableDetails = function (dt) {
        $("#tblDettaglioDocumento").find("tbody").sortable({
            opacity: 0.6,
            cursor: 'move',
            update: function (ev, ui) {
                var aIds = [];
                $("#tblDettaglioDocumento").find("tbody tr").each(function (index, obj) {
                    aIds.push($(obj).attr("data-id"));
                });
                $.post(app.baseUrl + "/documento/ordinamento_dettagli/" + documento.idDocumento, {aIds: aIds}, function (data) {
                    if (data.response) {
                        dt.table.draw("page");
                    } else {
                        app.error("", data.message);
                    }
                });
            }
        });
    };

    this.loadDettagli = function () {
        if (documento.idDocumento > 0) {
            // recupero la tipologia di documento

            var tipo = undefined;
            if (typeof documento.mappingTipologieDocumento[$("#id_tipo_documento").val()] != 'undefined') {
                switch (documento.mappingTipologieDocumento[$("#id_tipo_documento").val()]) {
                    case "ddt":
                        tipo = "ddt";
                        break;
                }
            }

            $.get("dettaglio_documento", {idDocumento: documento.idDocumento, dt: tipo}, function (data) {
                $("#frmDocumento").find("#dettagli").html(data);
            });
        }
    };

    this.loadPagamenti = function (disableBtnInserisciDaScadenza) {
        disableBtnInserisciDaScadenza = disableBtnInserisciDaScadenza || 0;

        if (documento.idDocumento > 0)
            $.get("pagamento", {idDocumento: documento.idDocumento}, function (data) {
                $("#frmDocumento").find("#pagamenti").html(data);

                if (disableBtnInserisciDaScadenza) {
                    $(".btnAggiungiScadenza").attr("disabled", "disabled");
                }

            });
    };




    this.generaRicevuteDaDDT = function (dateDefault, id_ddt, id_ric) {
        var ids = dt.getSelectedRows();

        if (ids.length == 0) {
            app.warning("Selezionare almeno un elemento");
            return;
        }

        if (confirm("Vuoi generare le ricevute nella data del documento?\n Clicca su OK per confermare \n Clicca su annulla per specificare la data")) {
            $.post(app.baseUrl + "/documento/elabora_generazione_automatica", {
                elimina_pagamenti_proforma_da_scadenziario: true,
                tipoFrom: id_ddt,
                tipoTo: id_ric,
                dataNuovo: date_ric,
                raggruppa_prodotti_ddt: false,
                add_indirizzo_spedizione: false,
                accorpa_fattura_per_soggeto: 1,
                accorpa_fattura_per_soggeto_unita_locale: 0,
                ids: ids,
                generaDaDDT: true
            }, function (data) {
                if (data.response) {
                    if (confirm("Generazione di " + data.message + " documenti avvenuta con successo. Accedere alla gestione dei documenti?")) {
                        app.href(app.baseUrl + "/documento?type=RIC");
                    }else{
                        return;
                    }
                    app.block(0);
                } else {
                    app.error("", data.message);
                    app.block(0);
                }
            }).error(function(data){
                if(typeof data.responseJSON != 'undefined' && typeof data.responseJSON.message != 'undefined') {
                    app.error(data.responseJSON.message);
                    app.block(0);
                }
            });
        }else{
            var date_ric = prompt("Inserisci la data in cui verranno emesse le ricevute fiscali", dateDefault);

            if (date_ric == null) {
                app.warning("Per procedere è necessario indicare una data per le ricevute");
                return;
            }

            app.block(1);
            $.post(app.baseUrl + "/documento/elabora_generazione_automatica", {
                elimina_pagamenti_proforma_da_scadenziario: true,
                tipoFrom: id_ddt,
                tipoTo: id_ric,
                dataNuovo: date_ric,
                raggruppa_prodotti_ddt: false,
                add_indirizzo_spedizione: false,
                accorpa_fattura_per_soggeto: 1,
                ids: ids
            }, function (data) {
                if (data.response) {
                    if (confirm("Generazione di " + data.message + " documenti avvenuta con successo. Accedere alla gestione dei documenti?")) {
                        app.href(app.baseUrl + "/documento?type=RIC");
                    }
                    app.block(0);
                } else {
                    app.error("", data.message);
                    app.block(0);
                }
            }).error(function(data){
                if(typeof data.responseJSON != 'undefined' && typeof data.responseJSON.message != 'undefined') {
                    app.error(data.responseJSON.message);
                    app.block(0);
                }
            });

        }

    };

    this.stampaMultipla = function () {
        var ids = dt.getSelectedRows();

        if (ids.length == 0) {
            app.warning("Selezionare almeno un elemento");
            return;
        }

        app.block(1);
        $.post(app.baseUrl + "/documento/stampa_multipla", {ids: ids})
            .done(function (data) {
                if (data.response) {
                    app.success(data.message);
                } else {
                    app.warning(data.message);
                }

                app.block(0);
            })
            .fail(function () {
                app.block(0);
            });
    };

    this.bindGeneraScarica = function () {
        $('[data-interaction=genera]').unbind('click').bind('click', function () {
            documento.stampa(dt.getSelectedRows(), $(this).attr('data-format'), 0);
        });

        $('[data-interaction=scarica]').unbind('click').bind('click', function () {


            var ids = dt.getSelectedRows();
            var format = $(this).attr('data-format');
            var zip = $(this).attr('data-zip');

            if (ids.length == 0) {
                app.warning("", "Devi selezionare almeno un documento!");
                return;
            }

            app.block(1);
            $.post(dt.baseUrl + "/zip", {ids: ids, format: format})
                .done(function (data) {
                    if (data.response) {
                        setTimeout(function(){
                            window.location.href = app.baseUrl + "/documento/download";
                        }, 1000);
                    } else {
                        app.warning("", data.message);
                    }
                    app.block(0);
                });
        });
    };

    var idTable = '';
    if (typeof dt != 'undefined')
        idTable = dt.idTable;

    this.stampa = function (ids, format, sync) {
        var type = $.trim($("#type").val()) != "" ? $.trim($("#type").val()) : undefined;
        format = format || 'pdf';

        if (typeof sync == 'undefined')
            sync = 1;

        if (ids.length == 0) {
            app.warning("", "Devi selezionare almeno un documento!");
            return;
        }

        app.block(1);
        $.post(app.baseUrl + "/documento/contabilizza", {ids: ids, format: format, sync: sync, type: type})
            .done(function (data) {
                if (data.response) {
                    if (ids.length == 1 && sync) {
                        // il documento è stato generato, ora posso scaricarlo
                        window.location.href = app.baseUrl + "/documento/download?" + $.param({
                            id: ids[0],
                            format: format,
                            v: Math.random()
                        });
                    } else {
                        documento.startInterval();
                    }
                } else {
                    //app.warning("", data.message);
                }
                app.block(0);
            })
            .fail(function () {
                app.block(0);
                app.error("", "Errore AJAX!");
            });

    };

    this.riepilogo = function () {

        if ($("#riepilogo").length > 0 && documento.idDocumento != 0) {
            $.post(app.baseUrl + '/documento/riepilogo/' + documento.idDocumento)
                .done(function (data) {
                    $("#riepilogo").html(data);
                })
                .fail(function (data) {
                    app.error("", "Errore AJAX!");
                });
        }
    };

    this.setPesoTotale = function () {
        if ($("#riepilogo").length > 0 && documento.idDocumento != 0) {
            $.post(app.baseUrl + '/documento/getPesoTotale/' + documento.idDocumento)
                .done(function (data) {
                    if (data.response) {
                        var updatePeso = true;
                        /*if ($("#ddt_peso_netto").val() != "" && $("#ddt_peso_netto").val() != data.message) {
                         updatePeso = false;
                         if (confirm("Il valore impostato per il peso netto ("+$("#ddt_peso_netto").val()+") è diverso da quello calcolato ("+data.message+"), aggiornarlo?"))
                         updatePeso = true;
                         }*/
                        if (updatePeso)
                            $("#ddt_peso_netto").val(data.message);
                    }
                    else
                        app.warning(data.message);
                })
                .fail(function (data) {
                    app.error("", "Errore AJAX!");
                });
        }
    };

    this.setPesoLordoTotale = function () {
        if ($("#riepilogo").length > 0 && documento.idDocumento != 0) {
            $.post(app.baseUrl + '/documento/getPesoLordoTotale/' + documento.idDocumento)
                .done(function (data) {
                    if (data.response) {
                        var updatePeso = true;
                        /*if ($("#ddt_peso_netto").val() != "" && $("#ddt_peso_netto").val() != data.message) {
                         updatePeso = false;
                         if (confirm("Il valore impostato per il peso netto ("+$("#ddt_peso_netto").val()+") è diverso da quello calcolato ("+data.message+"), aggiornarlo?"))
                         updatePeso = true;
                         }*/
                        if (updatePeso)
                            $("#ddt_peso").val(data.message);
                    }
                    else
                        app.warning(data.message);
                })
                .fail(function (data) {
                    app.error("", "Errore AJAX!");
                });
        }
    };

    this.generaFveDaFpfp = function (typeTo) {
        typeTo = typeTo||'FVE';
        var ids = dt.getSelectedRows();
        if (ids.length != 1) {
            app.warning("", "Per generare una fattura da una pro forma è necessario selezionare un documento per volta");
            return;
        }

        app.href(app.baseUrl + "/documento/generic_creazione_automatica?id_documento=" + ids[0] + "&from=FPF&to=" + typeTo);
    };

    var verificaGenerazioneDocumentiInProgress = false;

    this.verificaGenerazioneDocumenti = function (idTable) {
        if (verificaGenerazioneDocumentiInProgress) {
            console.debug("Skipping unterminated request 'verificaGenerazioneDocumenti'.")

            return;
        }

        var type = undefined;

        $.post(app.baseUrl + "/documento/verifica_coda", {type: type})
            .done(function (data) {
                if (data.response) {
                    $("#" + idTable + "_wrapper").find("[data-interaction='contractor_coda_stampa']").html("( " + data.message + " rimanenti )");
                    $("#" + idTable + "_wrapper").find("[data-interaction=btnGeneraDocumenti]").removeClass("fa-print").addClass("fa-spinner fa-spin blue");
                    $("#" + idTable + "_wrapper").find("[data-interaction=btnGeneraDocumenti]").closest('button').attr('disabled', 'disabled');

                    $('[data-interaction=contabilizza]').find('i').removeClass('fa-cogs').addClass('fa-spinner fa-spin');
                    $('[data-interaction=contabilizza]').attr('disabled', 'disabled');
                    $('[data-interaction=print]').attr('disabled', 'disabled');
                } else {
                    $("#" + idTable + "_wrapper").find("[data-interaction='contractor_coda_stampa']").html("");
                    $("#" + idTable + "_wrapper").find("[data-interaction=btnGeneraDocumenti]").removeClass("fa-spinner fa-spin green").addClass("fa-print");
                    $("#" + idTable + "_wrapper").find("[data-interaction=btnGeneraDocumenti]").closest('button').removeAttr('disabled');

                    $('[data-interaction=contabilizza]').find('i').removeClass('fa-spinner fa-spin').addClass('fa-cogs');
                    $('[data-interaction=contabilizza]').removeAttr('disabled');
                    $('[data-interaction=print]').removeAttr('disabled');

                    //abilito il pulsante invia email sulla situazione
                    if ($("[data-interaction=modalSendSituazione]").length > 0) {
                        $("[data-interaction=modalSendSituazione]").closest("button").attr("disabled", false);
                        $("[data-interaction=modalSendSituazione]").closest("button").html(documento.button_send_content);
                    }

                    // faccio il clear dell'intervallo
                    documento.clearInterval();
                }

                if (typeof dt != 'undefined')
                    dt.table.draw('page');
            })
            .always(function () {
                verificaGenerazioneDocumentiInProgress = false;
            });
    };

    this.bindEvents();

    this.clearInterval = function () {
        // se un interval è già in esecuzione lo elimino
        if (typeof documento.constructor.interval != "undefined")
            clearInterval(documento.constructor.interval);
    };

    this.startInterval = function () {
        this.clearInterval();

        documento.constructor.interval = setInterval(function () {
            documento.verificaGenerazioneDocumenti(idTable);
        }, 10000);

        documento.verificaGenerazioneDocumenti(idTable);
    };

    this.mettiInElaborazione = function (ids) {
        ids = ids || dt.getSelectedRows();

        if (ids.length == 0) {
            app.warning("", "Seleziona almeno un elemento!");
            return;
        }

        if (typeof documento.constructor.idDistinataConsegna == "undefined") {
            app.warning("", "Selezionare una distinta o crearne una nuova!");
            return;
        }

        app.block(1);
        $.post(app.baseUrl + "/documento/metti_in_elaborazione", {
            ids: ids,
            idDistintaConsegna: documento.constructor.idDistinataConsegna
        })
            .done(function (data) {
                if (data.response) {
                    dt.table.draw('page');
                    dt.selected_ids = [];
                    documento.constructor.afterMettiInElaborazione();
                } else {
                    app.warning("", data.message);
                }

                app.block(0);
            })
            .fail(function (data) {
                app.block(0);
                var error = "Errore AJAX!";
                if (typeof data.status != 'undefined' &&
                    data.status == 422 &&
                    typeof data.responseJSON == 'object' &&
                    typeof data.responseJSON.value != 'undefined' &&
                    data.responseJSON.value.length > 0
                ) {
                    error = data.responseJSON.value[0];
                }
                app.error("", error);
            });

        documento.constructor.afterMettiInElaborazione();
    };

    this.verificaVendite = function () {
        var ids = dt.getSelectedRows();

        if (ids.length == 0) {
            app.warning("", "Seleziona almeno un elemento!");
            return;
        }

        app.block(1);
        $.post(app.baseUrl + "/documento/verifica_vendite", {ids: ids})
            .done(function (data) {
                app.block(0);
                documento.mettiInConsegna(ids, data);
            })
            .fail(function (data) {
                app.block(0);
                var error = "Errore AJAX!";
                if (typeof data.status != 'undefined' &&
                    data.status == 422 &&
                    typeof data.responseJSON == 'object' &&
                    typeof data.responseJSON.value != 'undefined' &&
                    data.responseJSON.value.length > 0
                ) {
                    error = data.responseJSON.value[0];
                }
                app.error("", error);
            });
    };

    this.stampa_distinta_prodotti_consegna = function () {
        app.block(1);

        var ids = dt.getSelectedRows();

        $.post(app.baseUrl + "/documento/stampa_distinta_prodotti_consegna", {ids: ids})
            .done(function (data) {
                if (data.response) {
                    window.location.href = data.message.url; //app.baseUrl + "/soggetto/" + $("#idSoggetto").val() + "/scarica_situazione";
                } else {
                    app.warning("", data.message);
                }

                app.block(0);
            })
            .fail(function (data) {
                app.block(0);
                var error = "Errore AJAX!";
                if (typeof data.status != 'undefined' &&
                    data.status == 422 &&
                    typeof data.responseJSON == 'object' &&
                    typeof data.responseJSON.value != 'undefined' &&
                    data.responseJSON.value.length > 0
                ) {
                    error = data.responseJSON.value[0];
                }
                app.error("", error);
            });
    };

    this.mettiInConsegna = function (ids, data) {
        $("#modalVerificaVenditeBody").html(data);
        $("#modalVerificaVendite").modal('show');

        $('[data-interaction=mettiInConsegna]').unbind('click').bind('click', function () {
            app.block(1);
            $.post(app.baseUrl + "/documento/metti_in_consegna", {ids: ids})
                .done(function (data) {
                    if (data.response) {
                        app.success("", "Ordini messi in consegna");
                        $("#modalVerificaVendite").modal('hide');
                        dt.table.draw('page');
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    var error = "Errore AJAX!";
                    if (typeof data.status != 'undefined' &&
                        data.status == 422 &&
                        typeof data.responseJSON == 'object' &&
                        typeof data.responseJSON.value != 'undefined' &&
                        data.responseJSON.value.length > 0
                    ) {
                        error = data.responseJSON.value[0];
                    }
                    app.error("", error);
                });
        });
    };

    distinta_consegna.afterGeneraDistinta = function () {
        if (typeof dt != 'undefined')
            dt.table.draw('page');
    };

    distinta_consegna.finishSvincola = function () {
        if (typeof dt != 'undefined')
            dt.redrawPage();
    };

    this.generaDaCon = function (format) {
        var ids = dt.getSelectedRows();
        if (ids.length != 1) {
            app.warning("", "Per generare un documento è necessario selezionare un documento per volta");
            return;
        }
        app.href(app.baseUrl + "/documento/generic_creazione_automatica?id_documento=" + ids[0] + "&from=CON&to=" + format.toUpperCase());
    };

    this.bindGeneraDaConsuntivo = function () {
        $("[data-interaction=generaDaConsuntivo]").unbind("click").bind("click", function () {
            var format = $(this).attr("data-format");
            documento.generaDaCon(format);
        });
    };

    this.filterConsuntiviTutti = function () {
        app.href(app.baseUrl + "/documento?type=CON")
    };

    this.filterConsuntiviDaFatturare = function () {
        app.href(app.baseUrl + "/documento?type=CON&filterConsuntiviDaFatturare=1");
    };

    this.bindDropDownConsuntivi = function (types) {
        $("#" + dt.idTable + "_wrapper").find("#" + dt.idTable + "_filter").find('.dropdown-toggle').each(function () {
            var $div = $('<div class="dropdown" style="display: inline-block;"></div>');
            $(this).attr('data-toggle', 'dropdown');
            $div.html($(this).clone());

            var $ul = $('<ul class="dropdown-menu"></ul>');
            var interaction = $(this).hasClass('generaDaConsuntivo') ? 'generaDaConsuntivo' : '';
            if (interaction == 'generaDaConsuntivo') {
                $.each(types, function (i, v) {
                    $ul.append('<li><a style="cursor: pointer;" data-interaction="' + interaction + '" data-format="' + v[1] + '">' + v[0].toUpperCase() + '</a></li>');
                });
                $div.append($ul);

                $(this).replaceWith($div);
            }

        });
        this.bindGeneraDaConsuntivo();
    }

};

documento.interval = undefined;
documento.contabilizza = true;
documento.idDistinataConsegna = undefined;
documento.afterMettiInElaborazione = function () {
};
