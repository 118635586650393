var notification_queue = function (pusher_endpoint) {

    //evitare che la tendina si chiuda al click di un elemento
    $("#container-notification").find('.dropdown-menu').click(function (e) {
        e.stopPropagation();
    });

    var notification_queue = this;

    this.updateNotifications = function (data) {
        /**
         * data = {
         *   count: 0 (enabled=1)
         *   notification_queue: {
         *     id: ...
         *     type: ...
         *     name: ...
         *     percentage: 0-100
         *     url
         *     params
         *     ...
         *   }
         * }
         */

        $("#notification-counter").html(data.count);

        if(typeof data.notificationQueue == 'undefined'){
            return;
        }

        //controllo se la notifica è già presente e quindi da aggiornare oppure da creare
        var dom = $("li[data-id=" + data.notificationQueue.id + "]");
        if (dom.length > 0) {
            if (data.notificationQueue.percentage < 100) {
                dom.closest("div[data-role=percentage]").html(data.notificationQueue.percentage + " %");
                dom.closest("div[data-role=percentage_bar]").width(data.notificationQueue.percentage + " %");

            } else {
                dom.find("div[data-role=percentage_bar]").parent().remove();
                dom.find("div[data-role=description]").html("Completato");
                dom.find("span[data-role=percentage]").html("");
                dom.find("span[data-role=buttons]").html("<button style='font-size:13px;margin-left:10px;padding:0!important;' class='btn btn-link btn-info' title='Scarica' data-interaction='download_notification' data-id='" + data.notificationQueue.id + "' data-params='" + data.notificationQueue.params + "' data-url='" + data.notificationQueue.url + "' data-type='" + data.notificationQueue.type + "' ><i class='fa fa-download'></i></button>" +
                    "<button class='close btn btn-link btn-info' style='font-size:13px; margin-left:5px;padding:0!important;' data-interaction='disable_notification' data-id='" + data.notificationQueue.id + "' ><i class='fa fa-times'></i></button>");
            }
        } else {
            var li = $("<li data-id='" + data.notificationQueue.id + "'>");
            var append = "<a>" +
                "<div class='clearfix'>" +
                "<span class='pull-left'>" + data.notificationQueue.name + "</span>" +
                "<span class='pull-right' data-role='buttons' ><button class='close btn btn-link btn-info' style='font-size:13px; margin-left:5px;padding:0!important;' data-interaction='disable_notification' data-id='" + data.notificationQueue.id + "' ><i class='fa fa-times'></i></button></span>";

            if (data.notificationQueue.type == 10) {
                append += "<span class='pull-right' data-role='percentage'>" + data.notificationQueue.percentage + " %</span></div>" +
                    "<div class='progress progress-mini'>" +
                    "<div style='width:" + data.notificationQueue.percentage + "%' data-role='percentage_bar' class='progress-bar'></div>" +
                    "</div>";
            } else {
                append += "<span class='pull-right' data-role='percentage'>In elaborazione...</span></div>" +
                    "<div class='progress progress-mini progress-striped active'>" +
                    "<div data-role='percentage_bar' style='width:100%' class='progress-bar'></div>" +
                    "</div>" +
                    "<span style='font-size: 11px;'>" + moment(data.notificationQueue.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') + "</span>";
            }
            append += "<div data-role='description' class='msg-time'>";
            if (data.notificationQueue.description == null)
                append += "</div>";
            else
                append += data.notificationQueue.description + "</div>";
            append += "</a>";

            li.append(append);
            $("#notification-menu").append(li);
            $("#container-notification").removeClass("invisible");
            $("#container-notification").show();
            $("#notification-counter").html(data.count);

        }
        this.bindEvents();
    };
    /**
     * chiusura di una notifiche ( metto a false il campo enabled su DB )
     * @param button
     */
    this.closeNotification = function (button) {
        button = $(button);
        $("li[data-id=" + button.attr("data-id") + "]").hide();
        $.post(app.baseUrl + "/notification_queue/disable", {idNotification: button.attr("data-id")})
            .done(function (data) {
                if (data.response) {
                    var count = $("#notification-counter").text();
                    if (count == 1) {
                        $("#container-notification").hide();
                    }
                    else {
                        count--;
                        $("#notification-counter").html(count);
                    }
                }
                else {
                    app.warning(data.message);
                }
            })
            .fail(function (data) {
                app.error("", data);
            })
    };

    /**
     * Bind degli eventi dei buttons
     */
    this.bindEvents = function () {
        $("[data-interaction=disable_notification]").unbind("click").bind("click", function () {
            notification_queue.closeNotification(this)
        });
        $("[data-interaction=download_notification]").unbind("click").bind("click", function () {
            var type = parseInt($(this).attr("data-type"));
            switch (type) {
                case 10:

                    break;
                case 20:
                    window.location.href = $(this).attr("data-url");
                    $.post(app.baseUrl + "/notification_queue/downloaded", {idNotification: $(this).attr("data-id")})
                        .done(function (data) {
                            if (!data.response)
                                app.warning(data.message);
                        })
                        .fail(function (data) {
                            app.error("", data);
                        });
                    break;
            }
        });
    };

    this.bindEvents();


};